import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export const userStore = create(
  persist(
    (set) => ({
      isLoggedIn: false,
      fullName: '',
      profileId: '',
      bundles: [],
      claims: [],
      items: [],
      isValidated: false,
      isActive: false,
      deletedAccount: false,
      organizationId: '',
      createdRegularProfile: false,
      email: '',
      subscription: '',
      subscriptionUpgradeInProgress: false,
      organizations: [],
      workField: '',
      occupation: '',
      branchName: '',
      setUserStore: (
        isLoggedIn,
        fullName,
        profileId,
        bundles,
        claims,
        items,
        isValidated,
        isActive,
        deletedAccount,
        organizationId,
        createdRegularProfile,
        email,
        subscription,
        subscriptionUpgradeInProgress,
        organizations,
        workField,
        occupation,
        branchName,
      ) =>
        set({
          isLoggedIn,
          fullName,
          profileId,
          bundles,
          claims,
          items,
          isValidated,
          isActive,
          deletedAccount,
          organizationId,
          createdRegularProfile,
          email,
          subscription,
          subscriptionUpgradeInProgress,
          organizations,
          workField,
          occupation,
          branchName,
        }),
    }),
    {
      name: 'user-storage',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
